import React, {useState} from 'react';

import styles from "./styles.module.scss";

import {useParams, useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from "react-query";

import {getContext, login, putContext} from "../../services/services";
import {checkLogout401} from "../../services/auth";
import {IMessage} from "../../ts/interfaces/IMessage";

import MessageQuill from "../MessageQuill/MessageQuill";
import Preloader from '../../component/Preloader';

import Back from "../../component/Back/Back";

import {FiCheckCircle, FiExternalLink} from 'react-icons/fi';
import {toast} from "react-toastify";
import {EnumContextStatus} from "../../services/enum";
import {queryClient} from "../../services/queryClient";
import ModalConfirm from "../../component/ModalConfirm";

const ContextDetail = () => {

  const [openModalConfirm, setOpenModalConfirm] = useState(false)

  const params = useParams();
  const navigate = useNavigate();

  const {
    data,
    isLoading
  } = useQuery(['contexts', params.id], () => getContext(params.id ? params.id : '0'), {
    onSuccess: ({data}) => {
      if (!data) {
        navigate('/context');
      }
    },
    onError: error => {
      checkLogout401(error)
    },
    refetchOnWindowFocus: true,
    staleTime: 2000,
    enabled: true,
  })

  const {mutate, isLoading: isLoadingMutate} = useMutation((data: { status: string }) =>
    putContext(params.id ? params.id : '0', data), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['contexts']);
      setOpenModalConfirm(!openModalConfirm);
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      toast.error(error.message);
    }
  });


  return (<>
    <Preloader isVisible={isLoading}/>
    <Back to={"/context"}/>
    <div className={styles.contentWrapper}>

      <div className={styles.headerDetail}>
        {data && data.data.reference_name ? <>
          <p>{data.data.reference_name}</p>
          <div className={styles.headerBtns}>
            {data.data.status !== EnumContextStatus.resolvido ?
              <button disabled={isLoadingMutate} onClick={() => {
                setOpenModalConfirm(!openModalConfirm)
              }}>Marcar como resolvido <FiCheckCircle/></button> : ""}
            <a target={"_blank"} rel={"nofollow"}
               href={process.env.REACT_APP_CORPORATIVO + "/sac/pedido/login/email?email=" + encodeURIComponent(data.data.user_email)}>Login
              Aluno <FiExternalLink/></a>
          </div>
        </> : ""}
      </div>

      {data && data.data.messages.map((item: IMessage) => {
        return <MessageQuill key={item.id} messageParameter={item} readonly={!item.is_tutor} contextId={data.data.id}/>
      })}

      {data && !data.data.messages[data.data.messages.length - 1].is_tutor ?
        <MessageQuill readonly={false} contextId={data.data.id}/> : ""}

    </div>

    <ModalConfirm loading={isLoadingMutate}
                  open={openModalConfirm}
                  title={'Marcar resolvido'}
                  description={'Deseja mesmo marcar como resolvido?'}
                  textButton={'Salvar'}
                  setOpen={(state) => {setOpenModalConfirm(state)}}
                  action={() => mutate({status: EnumContextStatus.resolvido})}
                  btnConfirmColor={"success"}/>
  </>)
}

export default ContextDetail;
