import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import {useSearchParams} from "react-router-dom";
import {Row, Col, Form, Input, Label, Badge} from "reactstrap";
import AsyncSelect from 'react-select/async';

import Select from 'react-select';

import DatePicker, {registerLocale} from "react-datepicker";

import styles from "./styles.module.scss";

import {getContexts, IContext} from "../../services/services";

import {EnumContextStatus} from "../../services/enum";
import {checkLogout401} from "../../services/auth";
import {Link} from 'react-router-dom';


import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';

import Preloader from "../../component/Preloader";
import Pagination from "../../component/Pagination/Pagination";

export interface IFilter {
  page: number;
  status: string[];
  name: string;
  reference_name: string;
  email: string;
  updated_at: string;
}

interface ISelect2Data {
  reference_code: string;
  reference_name?: string;
};

const Context = () => {
  registerLocale('ptBR', ptBR);
  const [filter, setFilter] = useState<IFilter>({
    page: 1,
    status: ['pendente', 'respondido'],
    name: '',
    reference_name: '',
    email: '',
    updated_at: ''
  });
  const [filterAux, setFilterAux] = useState<IFilter>({
    page: 1,
    status: [],
    name: '',
    reference_name: '',
    email: '',
    updated_at: ''
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading,
    data,
  } = useQuery(['contexts', filter], () => getContexts(filter), {
    onSuccess: ({data}) => {
    },
    onError: error => {
      checkLogout401(error)
    },
    refetchOnWindowFocus: true,
    staleTime: 2000,
    // refetchInterval: 20000,
    enabled: true,
  });

  const options = [
    {value: EnumContextStatus.pendente, label: 'Pendente', color: '#4f5d73', bgColor: '#FFC107'},
    {value: EnumContextStatus.respondido, label: 'Respondido', color: '#4f5d73', bgColor: '#aad9ff'},
    {value: EnumContextStatus.resolvido, label: 'Resolvido', color: '#4f5d73', bgColor: '#cfffb0'},
  ]

  const references = data
    ? data.data.select2Data.map((item: ISelect2Data) => ({
      value: item.reference_name,
      label: item.reference_name,
    }))
    : [];


  const colorStyles = {
    control: (styles: any) => ({...styles, backgroundColor: "white"}),
    option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
      return {...styles, color: data.color, ":hover": {background: data.bgColor}};
    },

    multiValue: (styles: any, {data}: any) => {
      return {
        ...styles,
        backgroundColor: data.bgColor,
        color: "#fff",
      };
    },
    multiValueLabel: (styles: any, {data}: any) => {
      return {
        ...styles,
        color: data.color,
      };
    },
    multiValueRemove: (styles: any, {data}: any) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    }
  }
  const loadOptions = (searchValue: string, callback: (arg0: {
    value: string;
    label: string;
    color: string;
    bgColor: string;
  }[]) => void) => {
    setTimeout(() => {
      const filteredItem = options.filter((item) => {
        return item.label.toLowerCase().includes(searchValue.toLowerCase())
      });
      callback(filteredItem)
    }, 1000)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterAux({
      ...filterAux,
      [e.target.name]: e.target.value ? e.target.value : null
    });
  };

  const handleChangeSelect = (name: string, selectedOption: any) => {
    if (!selectedOption) {
      setFilterAux({
        ...filterAux,
        [name]: null,
      });
    } else {
      setFilterAux({
        ...filterAux,
        [name]: selectedOption.value ? selectedOption.value : null,
      });
    }
  };
  const handleChangeValue = (name: string, value: any) => {
    setFilterAux({
      ...filterAux,
      [name]: value,
    });
  };

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  useEffect(() => {
    filterAux.page = filter.page;
  }, [filter])

  useEffect(() => {
    if (searchParams.get('status') !== null) {
      let status = searchParams.get('status') === 'pendente' ? 'pendente' : (searchParams.get('status') === 'respondido' ? 'respondido' : "resolvido");
      setFilter({...filter, "status": [status]})
    }
  }, [searchParams]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Preloader isVisible={isLoading}/>
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.boxHeader}>
            <div className={styles.filter}>
              <Form onSubmit={(e) => {
                e.preventDefault();
                setFilter(filterAux);
              }}>
                <Row className={"row-cols-lg-auto g-3 align-items-center"}>
                  <Col xl={2}>
                    <Label
                      className="visually-hidden"
                      for="name"
                    >
                      Nome
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Nome"
                      type="text"
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                  <Col xl={2}>
                    <Label
                      className="visually-hidden"
                      for="email"
                    >
                      E-mail
                    </Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                  <Col xl={4}>
                    <Label
                      className="visually-hidden"
                      for="updated_at"
                    >
                      Data
                    </Label>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      className={'form-control'}
                      placeholderText={'Data modificação'}
                      locale={"ptBR"}
                      onChange={(update) => {
                        // @ts-ignore
                        setDateRange(update);

                        if (update[0] === null && update[1] === null)
                          handleChangeValue("updated_at", null);
                        else
                          handleChangeValue("updated_at", update.map((item) => {
                            return item ? item.toLocaleDateString() : null
                          }))
                      }}
                      isClearable={true}
                    />
                  </Col>
                  <Col xl={4}>
                    <Label
                      className="visually-hidden"
                      for="status"
                    >
                      Status
                    </Label>
                    <AsyncSelect
                      className="select_main"
                      // value={{'pendente', 'respondido'}}
                      loadOptions={loadOptions}
                      defaultValue={searchParams.get('status') === null ? options.filter((item) => {
                        return item.value === EnumContextStatus.pendente || item.value === EnumContextStatus.respondido
                      }) : options.filter((item) => {
                        return item.value === searchParams.get('status')
                      })}
                      closeMenuOnSelect={false}
                      defaultOptions
                      placeholder={'Status'}
                      isMulti
                      styles={colorStyles}
                      onChange={(e) => handleChangeValue("status", e.map((item) => {
                        return item.value;
                      }))}
                    />
                  </Col>
                </Row>
                <Row className={`row-cols-lg-auto g-3 align-items-center ${styles.rowFilter}`}>
                  <Col xl={4}>
                    <Label
                      className="visually-hidden"
                      for="reference_name"
                    >
                      Codigo de referencia
                    </Label>
                    <Select
                      isClearable
                      options={references}
                      placeholder={'Curso'}
                      onChange={(selectedOption) => handleChangeSelect("reference_name", selectedOption)}
                    />
                  </Col>
                  <Col xl={12} className={styles.colBtn}>
                    <button className={styles.filterBtn} disabled={isLoading}>
                      {isLoading ? "Filtrando..." : "Filtrar"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className={styles.listing}>
            <div className={styles.listingContent}>
              {data && data.data.contexts.map((item: IContext) => {
                return (
                  <div key={item.id} className={styles.listingItem}>
                    <div className={styles.listingItem2}>
                      <Col xl={4}>
                        <span className={styles.title}>{item.title}</span>
                      </Col>
                      <Col xl={4}>
                        <div className={styles.listingItem1}>
                          <a target={"_blank"} rel={"nofollow"}
                             href={process.env.REACT_APP_CORPORATIVO + "/sac/pedido/login/email?email=" + encodeURIComponent(item.user.email)}>
                            {item.user.name}<br/>
                            {item.user.email}
                          </a>
                        </div>
                      </Col>
                      <Col xl={1}>
                        <div className={styles.options}>
                          <Badge
                            color={item.status === EnumContextStatus.resolvido ? "success" : (item.status === EnumContextStatus.respondido ? "primary" : "warning")}>
                            {item.status}
                          </Badge>
                        </div>
                      </Col>
                      <Col xl={1}>
                        <span className={styles.date}>{item.updated_at}</span>
                      </Col>
                      <Col xl={1} className={styles.colBtn}>
                        <div className={styles.access}>
                          <Link className={styles.btnAccess}
                                to={'/context/' + item.id}>
                            Acessar
                          </Link>
                        </div>
                      </Col>
                    </div>
                  </div>
                );
              })}
            </div>
            <Pagination
              itemsPerPage={data?.data.per_page}
              totalRegisters={data?.data.total_items}
              paginate={paginate}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Context
