import api from './api';

import { UserLoginForm } from '../views/Login/Login';

export interface IUser {
  name: string
  email: string
}

export interface IContext {
  id: number,
  title: string,
  status: string,
  updated_at: string,
  user: IUser
}

export interface ITutorFilter{
  name: string,
  email: string,
  page: number
}

export interface ITutor{
  id: number,
  name: string,
  email: string,
  created_at: string,
  password: string,

}

export interface ITutors{
  data: ITutor[],
  total: number,
  current_page: number,
  last_page: number,
  id: number
}

export const login = (data: UserLoginForm) => Promise.resolve(api.post('/api/v1/tutoring/admin/login', data));

export const rememberPassword = (data: { email: string, token: string|null }) => Promise.resolve(api.post(`/api/v1/tutoring/remember-password`, data));

export const getContexts = (data: { status: string[] }) => Promise.resolve(api.get(`/api/v1/tutoring/admin/context`, {params: data}));

export const getContext = (id: string) => Promise.resolve(api.get(`/api/v1/tutoring/admin/context/` + id));

export const putContext = (id: string, data: {status: string}) => Promise.resolve(api.put(`/api/v1/tutoring/admin/context/` + id, data));

export const storeOrUpdateMessage = (data : { description: string, context_id: number, message_id?: number }) => Promise.resolve(api.post(`/api/v1/tutoring/admin/message/`, data));

export const getTutors = (data: { page: number }) => Promise.resolve(api.get(`/api/v1/tutoring/admin/tutor`, {params: data}));

export const getDashboard = () => Promise.resolve(api.get(`/api/v1/tutoring/admin/`));

export const storeOrUpdateTutor = (data : ITutor) => Promise.resolve(api.post(`/api/v1/tutoring/admin/tutor/`, data));

export const getTutor = (id: number) => Promise.resolve(api.get(`/api/v1/tutoring/admin/tutor/` + id));

export const deleteTutor = (id: number) => Promise.resolve(api.delete(`/api/v1/tutoring/admin/tutor/` + id));

