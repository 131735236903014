import React, {useState} from 'react';

import 'react-toastify/dist/ReactToastify.css';
import './styles.module.scss';
import menu from '../../assets/icons/icon-menu.svg'
import users from '../../assets/icons/icon-profile.svg'
import logo from "../../assets/icons/logo.svg";
import house from "../../assets/icons/icon-house.svg";
import msg from "../../assets/icons/icon-shape.svg";
import profile from '../../assets/icons/peopleIcon.svg';
import report from '../../assets/icons/icon-mapa.svg';
import arrowBottom from '../../assets/icons/arrowRightWhite.svg';

import styles from "./styles.module.scss";
import {Link, Outlet} from "react-router-dom";
import {useCookies} from "react-cookie";
import {TOKEN_KEY} from "../../services/auth";

const TheLayout = () => {

  const [showSideBar, setShowSideBar] = useState(false);
  const [cookies, setCookie] = useCookies(["jwt_token_tutoria"]);
  const [drawer, setDrawer] = useState(false);


  function logout() {
    setCookie("jwt_token_tutoria", "", {
      domain: window.location.hostname,
      path: "/",
      maxAge: -1
    });
    localStorage.removeItem('jwt_token_tutoria');
    document.location.href = "/";
  }

  return (
    <>
      {/*Header*/}

      <div className={styles.header}>
        <div className={styles.alignHeader}>
          <div className={styles.title}>
            <p>
              Tutoria Admin
            </p>
          </div>
          <div className={styles.sideBarButton}>
            <img src={menu} alt={"menu"} onClick={() => setShowSideBar(!showSideBar)}/>
          </div>
        </div>
        <div className={styles.profile}>
          <img src={profile} alt={"profile"} onClick={() => logout()}/>
        </div>
      </div>


      {/*SideBar*/}
      <div className={styles.menu + " " + (showSideBar ? styles.noSideBar : styles.sideBar)}>
        <div className={styles.info}>
          <div>
            <img src={logo} alt={"logo"}/>
          </div>
        </div>

        <Link to="/dashboard" className={styles.cards}>
          <img src={house} alt={"house"}/>
          <p>Home</p>
        </Link>
        <Link to="/tutors" className={styles.cards}>
          <img src={users} alt={"users"}/>
          <p>Tutores</p>
        </Link>
        <Link to="/context" className={styles.cards}>
          <img src={msg} alt={"msg"}/>
          <p>Dúvidas</p>
        </Link>
        <div className={styles.cardsDrawer}>
          <button onClick={() => setDrawer(!drawer)} className={styles.cards}>
            <div className={styles.alignMenu}>
              <img src={report} alt={"report"}/>
              <p>Relatórios
                <img className={`${styles.arrow} ${drawer ? styles.rotate : styles.rotateBack}`}
                     src={arrowBottom}
                     alt={"arrowBottom"}/>
              </p>
            </div>
          </button>
          {drawer &&
            <div className={styles.options}>
              <Link to="/relatorio" className={styles.drawer}>
                <p>Relatório mensal</p>
              </Link>
            </div>
          }
        </div>
      </div>
      <div className={styles.mainContainer + " " + (showSideBar ? styles.noSideBar : styles.sideBar)}>
        <Outlet/>
      </div>

    </>
  )
}

export default TheLayout
