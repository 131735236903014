import React from 'react';
import styles from "./styles.module.scss";

const MonthlyReport = () => {

  return (
    <>
      <div className={styles.content}>
        <div className={styles.box}>
        </div>
      </div>
    </>
  )
}

export default MonthlyReport
